'use client';

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Trans, useTranslation } from 'react-i18next';
import { sendYMEvent } from 'shared/lib/yandex-metrica';

const useIsScreenWidthGreaterThan = (width: number) => {
  const [isWidthGreaterThan, setIsWidthGreaterThan] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      setIsWidthGreaterThan(window.innerWidth > width);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  return isWidthGreaterThan;
};

function LogoCircle() {
  const country = process.env.NEXT_PUBLIC_COUNTRY || 'ru';
  return <img src={`/assets/logo-circle.${country}.svg`} alt={''} />;
}

function Layout({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();

  const [isMobileOpen, setMobileOpen] = useState(false);
  const isDesktop = useIsScreenWidthGreaterThan(960);
  if (isDesktop === undefined) return <Box />;
  return (
    <Box
      sx={{
        display: 'grid',
        minHeight: '100dvh',
        gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr',
        background: isDesktop ? 'transparent' : '#F9F8F8',
      }}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {!isDesktop && !isMobileOpen && (
          <Box
            pb={9}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              justifyContent: 'space-between',
              gap: 8,
            }}
          >
            <Box
              pt={8}
              px={5}
              sx={{
                fontSize: '22px',
                lineHeight: '24px',
                fontWeight: 500,
              }}
            >
              {t('loginIntroPage.title')}
            </Box>
            <Box px={5}>
              <LogoCircle />
            </Box>
            <Box
              component={'a'}
              href={t('loginIntroPage.actions.readMore.href')}
              target={'_blank'}
              px={5}
              sx={{
                font: 'inherit',
                display: 'block',
                fontSize: '16px',
                lineHeight: '16px',
                fontWeight: 500,
                color: '#FF5A1E',
                textTransform: 'uppercase',
                textDecoration: 'none',
              }}
            >
              {t('loginIntroPage.actions.readMore.title')}
            </Box>
            <Box>
              <Box
                component={'button'}
                onClick={() => {
                  setMobileOpen(true);
                  sendYMEvent('reachGoal', 'first_login');
                }}
                sx={{
                  font: 'inherit',
                  background: '#FF5A1E',
                  color: '#FFF',
                  lineHeight: '56px',
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  display: 'block',
                  width: '100%',
                  border: 0,
                }}
              >
                {t('loginIntroPage.actions.signIn')}
              </Box>
              <Box
                component={'a'}
                onClick={() => {
                  setMobileOpen(true);
                  sendYMEvent('reachGoal', 'first_signup');
                }}
                href={t('loginIntroPage.actions.signUp.href')}
                target={'_blank'}
                sx={{
                  display: 'block',
                  font: 'inherit',
                  color: 'inherit',
                  lineHeight: '56px',
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                }}
              >
                {t('loginIntroPage.actions.signUp.title')}
              </Box>
            </Box>
          </Box>
        )}
        <Box
          px={isDesktop ? 5 : 0}
          sx={{
            boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.10)',
            display: isDesktop ? 'block' : isMobileOpen ? 'block' : 'none',
          }}
        >
          {children}
        </Box>
        {(isDesktop || (!isDesktop && !isMobileOpen)) && (
          <Box
            py={2.5}
            px={5}
            sx={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              right: 0,
              display: 'block',
              background: '#D9D9D9',
              textAlign: 'center',
              fontSize: '12px',
              lineHeight: '16px',
              a: {
                textDecoration: 'underline',
                color: 'inherit',
              },
            }}
          >
            <Trans
              i18nKey={'loginIntroPage.legal.text'}
              components={[
                <a
                  href={t('loginIntroPage.legal.link')}
                  target={'_blank'}
                  rel={'nofollow'}
                  key={'legalLink'}
                />,
              ]}
            />
          </Box>
        )}
      </Box>
      <Box
        px={5}
        sx={{
          display: isDesktop ? 'flex' : 'none',
          background: '#F9F8F8',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 8,
        }}
      >
        <Box
          mx={'auto'}
          sx={{
            maxWidth: '460px',
            color: '#FF5A1E',
            fontSize: '52px',
            lineHeight: '52px',
            fontFamily: 'Odds',
            textWrap: 'balance',
            textAlign: 'left',
          }}
          pt={8}
        >
          {t('loginIntroPage.aside.title')}
        </Box>
        <Box
          pb={8}
          mx={'auto'}
          sx={{
            maxWidth: '460px',
          }}
        >
          <LogoCircle />
        </Box>
        <Box
          mx={'auto'}
          component={'a'}
          href={t('loginIntroPage.actions.readMore.href')}
          sx={{
            maxWidth: '460px',
            font: 'inherit',
            textDecoration: 'none',
            display: 'block',
            color: '#FF5A1E',
            fontSize: '16px',
            lineHeight: '16px',
            textTransform: 'uppercase',
            fontWeight: 500,
          }}
        >
          {t('loginIntroPage.actions.readMore.title')}
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
