import { Box } from '@mui/material';
import Image from 'next/image';
import { PropsWithChildren } from 'react';

type Props = {
  image?: string;
};

export function ErrorPage({ image, children }: PropsWithChildren<Props>) {
  return (
    <Box
      sx={{
        background: '#FFF',
        borderRadius: '3px',
        boxShadow:
          '0px 1.33px 3.5px 0px rgba(0, 0, 0, 0.06), 0px 1.25px 7px 0px rgba(0, 0, 0, 0.20)',
        textAlign: 'center',
        px: 2,
        py: 5,
        img: {
          maxWidth: '100%',
          height: '96px',
          objectPosition: 'center',
          objectFit: 'contain',
        },
      }}
    >
      {image && <Image height={96} width={240} alt={''} src={image} />}
      <Box pt={3}>
        <Box
          component={'span'}
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            color: '#323232',
            a: {
              display: 'block',
              color: '#FF5A1E',
              textDecoration: 'underline',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'none',
              },
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
