import type { TYMArgs, TYMReachGoalName } from './types';
import { ym } from './ym';

/**
 * Отправляет событие в Яндекс.Метрику.
 *
 * @param {string} [eventType="reachGoal"] - Тип события.
 * @param {TYMReachGoalName} target - Название цели.
 *
 * @example
 * sendYMEvent('reachGoal', 'purchase_form_smscode_resend');
 */

export const sendYMEvent = (eventType: 'reachGoal' = 'reachGoal', target: TYMReachGoalName) => {
  const tagID = Number(process.env.NEXT_PUBLIC_YANDEX_METRICA_ID) || null;
  ym(tagID, eventType, target);
};
