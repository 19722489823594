'use client';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { MouseEventHandler, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { ArrowLeftIcon, HelpIcon, MenuIcon, ShareIcon, XCrossIcon } from 'shared/ui/icon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Drawer } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Sidebar } from './sidebar';
import { CommonDialog } from 'entities/dialog';
import { sendGtmEvent } from 'shared/lib/send-gtm';
import { sendYMEvent } from 'shared/lib/yandex-metrica';

type Props = {
  title: string;
  onClose?: MouseEventHandler;
  onCloseHelp?: MouseEventHandler;
  onHelp?: MouseEventHandler;
  onBack?: MouseEventHandler;
  onMenu?: MouseEventHandler;
  onDemo?: MouseEventHandler;
  onShare?: MouseEventHandler;
  color?: 'primary' | 'white' | 'refund';
  sidebar?: boolean;
  closeConfirmation?: boolean;
};

const colorsMap: Record<
  string,
  {
    background: string;
    color: string;
  }
> = {
  white: {
    background: '#FFF',
    color: '#323232',
  },
  primary: {
    background: '#FF5A1E',
    color: '#FFF',
  },
  refund: {
    background: '#323232',
    color: '#FFF',
  },
};

export const Header = ({
  title,
  color = 'white',
  onBack,
  onHelp,
  onMenu,
  onClose,
  onCloseHelp,
  onShare,
  onDemo,
  sidebar,
  closeConfirmation = false,
}: Props) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 720px)');
  const colors = colorsMap[color];
  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirmation = useMemo(() => {
    if (!onClose || !closeConfirmation) {
      return null;
    }
    return {
      onClose: () => {
        setShowConfirmation(true);
      },
      Dialog: ({ open }: { open: boolean }) => {
        if (!open) return null;
        return (
          <CommonDialog.CloseConfirmation
            open
            onOk={onClose}
            onCancel={() => setShowConfirmation(false)}
            description={t('header.closeConfirmation.description')}
          />
        );
      },
    };
  }, [setShowConfirmation, closeConfirmation, onClose, showConfirmation]);

  return (
    <>
      <Box
        sx={{
          zIndex: 10,
          top: 0,
          position: 'sticky',
          backgroundColor: colors.background,
          '@media (min-width: 720px)': {
            position: 'static',
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box
          p={2}
          sx={{
            padding: '10px 16px',
            minHeight: '56px',
            display: 'flex',
            alignItems: 'center',
            color: colors.color,
            gap: '30px',
            '@media (min-width: 720px)': {
              color: 'inherit',
              px: 0,
              py: 5,
            },
          }}
        >
          {onBack && (
            <IconButton
              onClick={onBack}
              size="large"
              edge="start"
              color="inherit"
              sx={{
                p: 0,
                pl: 1,
                width: '32px',
                height: '32px',
                svg: {
                  pointerEvents: 'none',
                },
              }}
            >
              <ArrowLeftIcon />
            </IconButton>
          )}

          {onCloseHelp && (
            <IconButton
              onClick={onCloseHelp}
              size="large"
              edge="start"
              color="inherit"
              sx={{
                p: 0,
                pl: 1,
                width: '32px',
                height: '32px',
                svg: {
                  pointerEvents: 'none',
                },
              }}
            >
              <XCrossIcon />
            </IconButton>
          )}
          {!isDesktop && onMenu && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              sx={{
                p: 0,
                pl: 1,
                svg: {
                  pointerEvents: 'none',
                },
              }}
              onClick={() => {
                sendGtmEvent('main_menu');
                sendYMEvent('reachGoal', 'main_menu');
                setMenuOpen(true);
              }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            component="div"
            color={'inherit'}
            sx={{
              flexGrow: 1,
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 600,
              '@media (min-width: 720px)': {
                fontSize: '34px',
                lineHeight: '44px',
              },
            }}
          >
            {title}
          </Typography>
          {onDemo && (
            <Box
              py={'10px'}
              sx={{
                display: 'flex',
              }}
            >
              <Button
                onClick={onDemo}
                sx={{
                  borderRadius: 0,
                  background: '#FF5A1E',
                  height: '36px',
                  color: '#FFF',
                  py: 0,
                  fontSize: '14px',
                  lineHeight: '24px',
                  '&:hover': {
                    background: '#b23e15',
                  },
                }}
              >
                {t('demo')}
              </Button>
            </Box>
          )}
          {(onHelp || onClose || onShare) && (
            <Box
              sx={{
                display: 'flex',
                gap: 2,
              }}
            >
              {onHelp && (
                <IconButton
                  onClick={onHelp}
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{
                    p: 0,
                    width: '32px',
                    height: '32px',
                    svg: {
                      pointerEvents: 'none',
                    },
                  }}
                >
                  <HelpIcon />
                </IconButton>
              )}
              {onClose && (
                <>
                  <IconButton
                    onClick={confirmation?.onClose ?? onClose}
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{
                      p: 0,
                      width: '32px',
                      height: '32px',
                      svg: {
                        pointerEvents: 'none',
                      },
                    }}
                  >
                    <XCrossIcon />
                  </IconButton>
                  {confirmation && <confirmation.Dialog open={showConfirmation} />}
                </>
              )}

              {onShare && (
                <IconButton
                  onClick={onShare}
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{
                    p: 0,
                    width: '32px',
                    height: '32px',
                    svg: {
                      pointerEvents: 'none',
                    },
                  }}
                >
                  <ShareIcon color={'#FFF'} width={24} height={24} />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {sidebar && (
        <Drawer
          anchor={'left'}
          variant={isDesktop ? 'permanent' : undefined}
          open={isDesktop || menuOpen}
          color={'#F9F8F8'}
          onClose={() => {
            if (!isDesktop) {
              setMenuOpen(false);
            }
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Sidebar />
        </Drawer>
      )}
    </>
  );
};
