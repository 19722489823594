import { TYMEventParameters, TYMArgs } from './types';

/**
 * @description
 *   Эта функция является оберткой для оригинальной функции Яндекс.Метрики.
 *   Ее можно безопасно вызывать, даже если счетчик Яндекс.Метрики не загружен,
 *   или использовать для логирования и тестирования.
 *
 * @param {number | null} tagID Идентификатор счетчика Яндекс.Метрики
 * @param {...TYMEventParameters} parameters Параметры события Яндекс.Метрики
 *
 * @example
 *   ym(12345, 'reachGoal', 'myGoal');
 */
export const ym = (tagID: number | null, ...parameters: TYMEventParameters) => {
  const ym = window.ym as TYMArgs | undefined;

  if (!ym || !tagID || window.location.hostname !== 'mokka.ru') {
    return;
  }

  ym(tagID, ...parameters);
};
