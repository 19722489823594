import { useCallback, useContext } from 'react';
import { MetricaTagIDContext } from './yandex-metrica-provider';
import { ym } from './ym';
import { TYMReachGoalName } from './types';

export const useMetrica = () => {
  const tagID = useContext(MetricaTagIDContext);

  const sendYMEvent = useCallback(
    (eventType: 'reachGoal' = 'reachGoal', target: TYMReachGoalName) => {
      ym(tagID, eventType, target);
    },
    [tagID]
  );

  return { sendYMEvent };
};
