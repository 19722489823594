`use client`;
import Script, { ScriptProps } from 'next/script';
import React, { createContext, ReactNode, useMemo } from 'react';
import { IInitParameters } from './types';

export const MetricaTagIDContext = createContext<number | null>(null);

type YandexMetricaProviderProps = {
  children: ReactNode;
  strategy?: ScriptProps['strategy'];
  initParameters?: IInitParameters;
};

export const YandexMetricaProvider = ({
  children,
  strategy = 'afterInteractive',
  initParameters = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
}: YandexMetricaProviderProps) => {
  const YANDEX_METRICA_ID = process.env.NEXT_PUBLIC_YANDEX_METRICA_ID || '';
  const COUNTRY = process.env.NEXT_PUBLIC_COUNTRY;
  const id = YANDEX_METRICA_ID ? Number(YANDEX_METRICA_ID) : null;

  if (COUNTRY !== 'ru' || !id) {
    return <>{children}</>;
  }

  const scriptSrc = 'https://mc.yandex.ru/metrika/tag.js';

  return (
    <>
      <Script id="yandex-metrica" strategy={strategy}>
        {`
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();
          for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
          k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "${scriptSrc}", "ym");
          ym(${id}, "init", ${JSON.stringify(initParameters)});
        `}
      </Script>
      <MetricaTagIDContext.Provider value={id}>{children}</MetricaTagIDContext.Provider>
    </>
  );
};
